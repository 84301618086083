import { createAsyncThunk } from '@reduxjs/toolkit';

import { TRegionKey } from '@source/interface';
import { IErrorResponse } from '@source/interface/apiResponse';
import { ICreateScheduleViewingTicketRes } from '@source/interface/scheduleViewing';
import * as TicketActions from '@source/services/Ticket';
import { AxiosError } from 'axios';

const sliceName = 'TICKETS';

// export const createScheduleViewingTicket = createAsyncThunk(
//   `${sliceName}/createScheduleViewingTicket`,
//   async ({ country, payload, isAuthenticated }: { country: TRegionKey; payload: object; isAuthenticated?: boolean }) =>
//     TicketActions.createScheduleViewingTicket({ country, payload, isAuthenticated }),
// );

export const createScheduleViewingTicket = createAsyncThunk<
  ICreateScheduleViewingTicketRes,
  { country: TRegionKey; payload: Record<string, any>; isAuthenticated: boolean },
  {
    rejectValue: IErrorResponse;
  }
>(
  `${sliceName}/createScheduleViewingTicket`,
  async (
    { country, payload, isAuthenticated }: { country: TRegionKey; payload: object; isAuthenticated?: boolean },
    { rejectWithValue },
  ) => {
    try {
      const res = await TicketActions.createScheduleViewingTicket({ country, payload, isAuthenticated });

      return res.data;
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const createTicket = createAsyncThunk(
  `${sliceName}/createTicket`,
  async ({ country, payload }: { country: TRegionKey; payload: object }) =>
    TicketActions.createTicket({ country, payload }),
);

export const createBuyHuntTicket = createAsyncThunk(
  `${sliceName}/createBuyHuntTicket`,
  async ({ country, payload, isAuthenticated }: { country: TRegionKey; payload: object; isAuthenticated?: boolean }) =>
    TicketActions.createBuyHuntTicket({ country, payload, isAuthenticated }),
);

export const createNotifyMeTicket = createAsyncThunk(
  `${sliceName}/createBuyHuntNotifyMeTicket`,
  async ({ country, payload }: { country: TRegionKey; payload: object }) =>
    TicketActions.createNotifyMeTicket({ country, payload }),
);

export default createScheduleViewingTicket;
