import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import flattenDeep from 'lodash/flattenDeep';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { CheckCircleFilled, EditOutlined } from '@ant-design/icons';

import { deepCopy, isDotNotation } from '@design-system/utils/utils';
import { BuyHuntFilterProps, SettingProps } from '@design-system/components/FiltersV1/FilterProps';

import useBrowseServices from '@source/hooks/useBrowseServices';
import useCurrentFilterSettingsOnChange from '@source/hooks/useCurrentFilterSettingsOnChange';

import { getNotifyMeFilterSettings, getNotifySearchFilters } from '@source/pages/Home/components/BuyHunt/utils';
import { IBuyHuntSelectedOption } from '@source/pages/Home/components/BuyHunt/BuyHuntProps';

const StyledSearchFilters = styled.div`
  margin-bottom: 16px;

  .label-text {
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
    margin-bottom: 8px;
  }

  .search-filters-box {
    background: ${(props) => props.theme.color.surface};
    box-shadow: inset 0px 0px 14px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 10px;
    min-height: 61px;
    overflow: hidden;
  }

  .view-more-less {
    margin-top: 8px;
    font-size: 12px;
    line-height: 140%;
    color: ${(props) => props.theme.color.linkHighEmphasis};
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledSearchOption = styled.div<{ isActive?: boolean; index: number }>`
  display: inline-block;
  height: 31px;
  line-height: 140%;
  font-size: 12px;
  border-radius: 60px;
  padding: 7px;
  cursor: pointer;
  margin: 5px 5px 10px;

  ${(props) =>
    props.isActive
      ? css`
          color: ${props.theme.color.linkHighEmphasis};
          background: ${props.theme.color.linkLowEmphasis};
        `
      : css`
          color: ${props.theme.color.linkHighEmphasis};
          background: none;
          border: 1px solid ${props.theme.color.linkHighEmphasis};
        `}

  &.disabled {
    background: ${(props) => props.theme.color.border};
    color: ${(props) => props.theme.color.onBackgroundLowEmphasis};
  }
`;

const StyledEditFilter = styled.div`
  padding: 7px 10px;
  cursor: pointer;
  margin: 5px 5px 3px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.color.linkHighEmphasis};
  color: ${(props) => props.theme.color.linkHighEmphasis};
  width: max-content;
  font-size: 12px;
`;

type NotifyMeSearchFiltersProps = {
  lang: string;
};

const NotifyMeSearchFilters = ({ lang: language }: NotifyMeSearchFiltersProps) => {
  const { browseState, setNotifyMeSearchFilterKeywords, setIsFromNotifyMe } = useBrowseServices();
  const { currentQuery, currentFilterSettings } = browseState;
  const { t } = useTranslation();

  const activeFilterSetting = useMemo(
    () =>
      currentFilterSettings?.filter((setting: SettingProps) =>
        setting?.filters?.some(
          (item: BuyHuntFilterProps) =>
            (item?.selectedOptions && item?.selectedOptions?.length) || item?.selectedBoolean,
        ),
      ),
    [currentFilterSettings, language],
  );

  const searchFilterBoxRef = useRef<HTMLDivElement>(null);

  const [notifyMeSetting, setNotifyMeSetting] = useState<Record<
    string,
    string | IBuyHuntSelectedOption[] | IBuyHuntSelectedOption
  > | null>(getNotifyMeFilterSettings(activeFilterSetting, t));

  const [notifyMeFilters, setNotifyMeFilters] = useState<string[]>(getNotifySearchFilters(notifyMeSetting, t));

  const [disabledFilters, setDisabledFilters] = useState<string[]>([]);

  useEffect(() => {
    setNotifyMeSetting(getNotifyMeFilterSettings(activeFilterSetting, t));
  }, [activeFilterSetting]);

  useEffect(() => {
    if (disabledFilters.length > 0) {
      const notifySearchFilters = Object.keys(notifyMeSetting || {}).reduce((nSetting, settingKey) => {
        const notifyMeSettingValue = notifyMeSetting ? notifyMeSetting[settingKey] : {};
        const newSetting = deepCopy(nSetting);

        if (isArray(notifyMeSettingValue)) {
          const notIncludedDisabledFilter = notifyMeSettingValue.filter((value: IBuyHuntSelectedOption | string) => {
            const theValue =
              // eslint-disable-next-line no-nested-ternary
              typeof value === 'object'
                ? isDotNotation(value.formattedValue)
                  ? t(value?.formattedValue || '')
                  : value.formattedValue
                : value;
            return !disabledFilters.includes(theValue || '');
          });
          newSetting[settingKey] = notIncludedDisabledFilter;
        } else {
          const notifyMeSettingFormattedValue =
            typeof notifyMeSettingValue === 'object' ? notifyMeSettingValue?.formattedValue : notifyMeSettingValue;
          if (!disabledFilters.includes(notifyMeSettingFormattedValue || '')) {
            newSetting[settingKey] = notifyMeSettingValue;
          }
        }
        return newSetting;
      }, {});
      setNotifyMeSearchFilterKeywords(notifySearchFilters);
    } else {
      setNotifyMeSearchFilterKeywords(notifyMeSetting);
    }
  }, [disabledFilters, notifyMeSetting, setNotifyMeSearchFilterKeywords]);

  const handleFilterSettingsChange = () => {
    const notifyMeSettings = getNotifyMeFilterSettings(activeFilterSetting, t);
    const notifySearchFilters = getNotifySearchFilters(notifyMeSettings || {}, t);

    // if user query on search bar, add query to make/model search
    if (currentQuery.length > 0) {
      notifySearchFilters.push(currentQuery.toUpperCase());
    }

    setNotifyMeSetting(notifyMeSettings);
    setNotifyMeSearchFilterKeywords(notifyMeSettings);
    setNotifyMeFilters(uniq(flattenDeep(notifySearchFilters)));
  };

  useCurrentFilterSettingsOnChange(handleFilterSettingsChange);

  const handleSearchFilterOptionClick = (label: string) => () => {
    if (disabledFilters.includes(label)) {
      setDisabledFilters(disabledFilters.filter((disabledFilter) => disabledFilter !== label));
    } else {
      setDisabledFilters([...disabledFilters, label]);
    }
  };

  const renderSearchFilterOption = (label: string, index: number) => (
    <StyledSearchOption
      index={index}
      key={label}
      onClick={handleSearchFilterOptionClick(label)}
      isActive
      className={disabledFilters.includes(label) ? 'disabled' : ''}
    >
      {label}&nbsp;
      <CheckCircleFilled />
    </StyledSearchOption>
  );

  const handleEditFilter = () => {
    setIsFromNotifyMe(true);
    window.scrollTo({ top: 0 });
  };

  const renderSearchFilterOptions = () => {
    const notifyFilters: { [key: string]: any } = notifyMeFilters || {};
    return (
      <div>
        <StyledEditFilter onClick={handleEditFilter}>
          <EditOutlined style={{ marginRight: '4px' }} />
          {t('shared.buyHuntSection.edit')}
        </StyledEditFilter>
        {notifyFilters.map((filter: string, index: number) => renderSearchFilterOption(filter, index))}
      </div>
    );
  };
  if (isEmpty(notifyMeFilters)) {
    return null;
  }

  return (
    <StyledSearchFilters>
      <div className="label-text">{t('shared.buyHuntSection.headTitle')}</div>
      <div className="search-filters-box" ref={searchFilterBoxRef}>
        {renderSearchFilterOptions()}
      </div>
    </StyledSearchFilters>
  );
};

export default NotifyMeSearchFilters;
