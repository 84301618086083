import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';

import * as actions from '@source/redux/slice/browseSlice';
import { useAppDispatch, useAppSelector } from '@source/hooks/useStateHook';

import { SettingProps } from '@design-system/components/FiltersV1/FilterProps';
import { FilterConfigProps } from '@source/pages/Home/components/Filters/configs';
import { SelectedSearchFilterType } from '@source/types';
import { TLanguageKey, TRegionKey } from '@source/interface';

const useBrowseServices = () => {
  const dispatch = useAppDispatch();

  /** Selectors */
  const browseState = useAppSelector((state) => state.browse, shallowEqual);

  /** Dispatched Funcs  */
  const setCurrentFilterSettings = useCallback(
    (data: SettingProps[]) => dispatch(actions.setCurrentFilterSettings(data)),
    [dispatch],
  );
  const setInitialFilterSettings = useCallback(
    (data: any) => dispatch(actions.setInitialFilterSettings(data)),
    [dispatch],
  );
  const setCurrentQuery = useCallback((data: string) => dispatch(actions.setCurrentQuery(data)), [dispatch]);
  const setSyncUpUrlToFilter = useCallback((data: boolean) => dispatch(actions.setSyncUpUrlToFilter(data)), [dispatch]);

  const setNotifyMeSearchFilterKeywords = useCallback(
    (data: any) => dispatch(actions.setNotifyMeSearchFilterKeywords(data)),
    [dispatch],
  );

  const setIsFromNotifyMe = useCallback((data: boolean) => dispatch(actions.setIsFromNotifyMe(data)), [dispatch]);

  const setFilterChangeCount = useCallback((data: number) => dispatch(actions.setFilterChangeCount(data)), [dispatch]);

  const setSelectedSearchFilters = useCallback(
    (data: SelectedSearchFilterType) => dispatch(actions.setSelectedSearchFilters(data)),
    [dispatch],
  );

  const setCurrentFilterConfigs = useCallback(
    (data: FilterConfigProps) => dispatch(actions.setCurrentFilterConfigs(data)),
    [dispatch],
  );

  const setPopularCars = useCallback((data: any) => dispatch(actions.setPopularCars(data)), [dispatch]);

  const setFilterLocationOptions = useCallback(
    (data: any) => dispatch(actions.setFilterLocationOptions(data)),
    [dispatch],
  );

  return {
    browseState,
    setCurrentFilterSettings,
    setInitialFilterSettings,
    setCurrentQuery,
    setFilterChangeCount,
    setSyncUpUrlToFilter,
    setSelectedSearchFilters,
    setCurrentFilterConfigs,
    setNotifyMeSearchFilterKeywords,
    setIsFromNotifyMe,
    setPopularCars,
    setFilterLocationOptions,
  };
};

export default useBrowseServices;
