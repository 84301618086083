import isArray from 'lodash/isArray';
import { BuyHuntFilterProps, SettingProps } from '@design-system/components/FiltersV1/FilterProps';
import { IBuyHuntSelectedOption } from '@source/pages/Home/components/BuyHunt/BuyHuntProps';
import { getNewTagLabel } from '@design-system/utils/filters';
import { isDotNotation } from '@design-system/utils/utils';

export const getNotifyMeFilterSettings = (activeFilterSetting: SettingProps[], t: any) => {
  if (activeFilterSetting) {
    const result: { [key: string]: any } = {};
    activeFilterSetting.forEach((setting: SettingProps) => {
      setting?.filters?.forEach((filter: BuyHuntFilterProps) => {
        type TypeFilterName = keyof typeof result;
        if (setting.isBooleanFilter || filter.type === 'boolean') {
          result[filter.name as TypeFilterName] = {
            label: t(filter.header),
            value: Boolean(filter.selectedBoolean),
            formattedValue: t(filter.header),
            type: filter.type,
          };
        } else {
          result[filter.name as TypeFilterName] = filter?.selectedOptions?.map((option) => ({
            label: t(option.label) || t(filter.header),
            min: filter.selectedMinInput || filter.selectedMin,
            max: filter.selectedMaxInput || filter.selectedMax,
            value: option?.value,
            formattedValue: option?.customLabel || getNewTagLabel(filter, option, t) || t(option?.label),
            type: filter.type,
            displayMultiplier: filter.displayMultiplier,
          }));
        }
      });
    });
    return result;
  }
  return null;
};

export const getNotifySearchFilters = (filters: any, t: any) => {
  if (!filters) {
    return [];
  }

  return Object.keys(filters).map((settingKey) => {
    if (typeof filters[settingKey] === 'object') {
      const setting = (filters[settingKey] as IBuyHuntSelectedOption) || {};
      if (setting?.formattedValue) return setting.formattedValue;
    }

    if (isArray(filters[settingKey])) {
      const settings = (filters[settingKey] as IBuyHuntSelectedOption[]) || [];
      return settings.map((set) => {
        if ('formattedValue' in set) {
          return isDotNotation(set?.formattedValue) ? t(set?.formattedValue) : set?.formattedValue;
        }

        return set;
      });
    }

    return filters[settingKey];
  });
};
