import axios from 'axios';

import { API_URL, WHOLESALE_URL } from '@source/constants/api';
import { TRegionKey } from '@source/interface';
import { getAxiosInstance, getAccessToken } from '@source/utils/utils';
import { WHOLESALE_AXIOS_USER_AUTH } from '@source/services/UserProfile';

export const createScheduleViewingTicket = async ({
  country,
  payload,
  isAuthenticated = false,
}: {
  country: TRegionKey;
  payload: object;
  isAuthenticated?: boolean;
}) => {
  const { scheduleViewing } = API_URL(country);
  // const scheduleViewing = 'http://localhost:8000/viewing-schedule';

  if (isAuthenticated) {
    return WHOLESALE_AXIOS_USER_AUTH.post(scheduleViewing, payload);
  }

  return axios.post(scheduleViewing, payload);
};

export const createTicket = async ({ country, payload }: { country: TRegionKey; payload: object }): Promise<object> => {
  const authAxios = getAxiosInstance(`${WHOLESALE_URL}/api/v1`, getAccessToken);
  const { buyNow } = API_URL(country);
  return authAxios.post(buyNow, payload);
};

export const createNotifyMeTicket = async ({
  country,
  payload,
}: {
  country: TRegionKey;
  payload: object;
}): Promise<object> => {
  const authAxios = getAxiosInstance(`${WHOLESALE_URL}/api/v1`, getAccessToken);
  const { notifyMe } = API_URL(country);
  return authAxios.post(notifyMe, payload);
};

export const createBuyHuntTicket = async ({
  country,
  payload,
  isAuthenticated = false,
}: {
  country: TRegionKey;
  payload: object;
  isAuthenticated?: boolean;
}): Promise<object> => {
  const { buyHunt } = API_URL(country);

  if (isAuthenticated) {
    const authResponse = await WHOLESALE_AXIOS_USER_AUTH.post(buyHunt, payload);
    return authResponse?.data?.data;
  }

  const response = await axios.post(buyHunt, payload);
  return response?.data?.data;
};

export const createUsedCarLoanTicket = async ({
  country,
  payload,
}: {
  country: TRegionKey;
  payload: object;
}): Promise<object> => {
  const { usedCarLoan } = API_URL(country);
  const response = await axios.post(usedCarLoan, payload);
  return response?.data?.data;
};

export default createScheduleViewingTicket;
