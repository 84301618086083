import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useAppDispatch } from '@source/hooks/useStateHook';
import * as action from '@source/redux/slice/ticket';
import { getRecaptchaToken } from '@design-system/utils/utils';
import { TRegionKey } from '@source/interface';

interface ITicketParams {
  country: TRegionKey;
  payload: any;
  isAuthenticated?: boolean;
}

const useTicketService = () => {
  const dispatch = useAppDispatch();
  const { isReady, pathname } = useRouter();

  const createScheduleViewingTicket = ({ country, payload, isAuthenticated = false }: ITicketParams): any => {
    const tokenAction = country === 'my' ? 'SpeakToUs' : 'ScheduleViewing';

    const createScheduleViewingTicketWithToken = (token: string) =>
      dispatch(
        action.createScheduleViewingTicket({
          country,
          payload: {
            ...payload,
            g_recaptcha_response: token,
            g_recaptcha_action: `${country}_${tokenAction}`,
          },
          isAuthenticated,
        }),
      );

    return getRecaptchaToken(
      createScheduleViewingTicketWithToken,
      createScheduleViewingTicketWithToken,
      `${country}_${tokenAction}`,
    );
  };

  const createTicket = useCallback(
    ({ country, payload }: ITicketParams) => {
      const currentPage = pathname.replace(/[^a-zA-Z]/g, '');
      const tokenAction = `${country.toUpperCase()}_${currentPage}_createTicket`;

      const createTicketWithToken = (token: string) =>
        dispatch(
          action.createTicket({
            country,
            payload: {
              ...payload,
              g_recaptcha_response: token,
              g_recaptcha_action: tokenAction,
            },
          }),
        );

      return getRecaptchaToken(createTicketWithToken, createTicketWithToken, tokenAction);
    },
    [dispatch, isReady],
  );

  const createBuyHuntTicket = ({ country, payload, isAuthenticated }: ITicketParams): any => {
    const createBuyHuntTicketWithToken = (token: string) =>
      dispatch(
        action.createBuyHuntTicket({
          country,
          payload: {
            ...payload,
            g_recaptcha_response: token,
            g_recaptcha_action: `${country}_BuyHunt`,
          },
          isAuthenticated,
        }),
      );

    return getRecaptchaToken(createBuyHuntTicketWithToken, createBuyHuntTicketWithToken, `${country}_BuyHunt`);
  };

  const createNotifyMeTicket = ({ country, payload }: ITicketParams): any => {
    const createNotifyMeTicketWithToken = (token: string) =>
      dispatch(
        action.createNotifyMeTicket({
          country,
          payload: {
            ...payload,
            g_recaptcha_response: token,
            g_recaptcha_action: `${country.toUpperCase()}_NotifyMe`,
          },
        }),
      );

    return getRecaptchaToken(createNotifyMeTicketWithToken, createNotifyMeTicketWithToken, `${country}_BuyHunt`);
  };

  return {
    createTicket,
    createScheduleViewingTicket,
    createBuyHuntTicket,
    createNotifyMeTicket,
  };
};

export default useTicketService;
