/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Col, Form, Row } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { TLanguageKey } from '@source/interface';
import { URL_CONFIG } from '@source/constants/urlConfig';
import { getFormInput } from '@source/constants/forms';
import FormItems from '@source/components/FormItems/FormItems';
import BuyHuntSearchFilter from '@source/pages/Home/components/BuyHunt/BuyHuntSearchFilter';
import useRegionConfig from '@source/hooks/useRegionConfig';
import Button from '@source/components/CustomButton/CustomButton';

interface BuyHuntProps {
  form: any;
  lang: string;
  onSubmit: () => void;
  isSubmitting: boolean;
  disableSubmitButton: boolean;
  handleFormValueChange: () => void;
  bellIcon: string;
}

const BuyHuntContainer = ({
  form,
  lang,
  onSubmit,
  isSubmitting,
  disableSubmitButton,
  handleFormValueChange,
  bellIcon,
}: BuyHuntProps) => {
  const {
    countryConfig: { country },
  } = useRegionConfig();
  const { t } = useTranslation();
  const { name, makeModel, phone, agreement } = getFormInput(country || 'sg', t);

  /**
   * gets translation component label for agreement option
   *
   * @returns {any} object of components for translation
   */
  const getTranslationComponent = (): any => {
    let components = {};
    // ignoring the eslint for translation with component
    switch (country) {
      case 'sg':
        components = {
          // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
          a1: (
            <a
              href={URL_CONFIG({ region: 'sg', lang: i18next.language as TLanguageKey, path: 'terms' }).formatted}
              target="_blank"
              rel="noreferrer"
            />
          ),
          // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
          a2: (
            <a
              href={
                URL_CONFIG({
                  region: 'sg',
                  lang: i18next.language as TLanguageKey,
                  path: 'terms#privacy-policy',
                }).formatted
              }
              target="_blank"
              rel="noreferrer"
            />
          ),
        };
        break;
      case 'id':
        components = {
          a1: (
            <a
              href={URL_CONFIG({ region: 'id', lang: i18next.language as TLanguageKey, path: 'terms' }).formatted}
              target="_blank"
              rel="noreferrer"
            />
          ),
        };
        break;
      case 'th':
        components = {
          a1: (
            <a
              href={URL_CONFIG({ region: 'th', lang: i18next.language as TLanguageKey, path: 'terms' }).formatted}
              target="_blank"
              rel="noreferrer"
            />
          ),
        };
        break;
      case 'my':
        components = {
          a1: (
            <a
              href={
                URL_CONFIG({ region: 'my', lang: i18next.language as TLanguageKey, path: 'terms#term-of-use' })
                  .formatted
              }
              target="_blank"
              rel="noreferrer"
            />
          ),
          a2: (
            <a
              href={
                URL_CONFIG({ region: 'my', lang: i18next.language as TLanguageKey, path: 'terms#privacy-notice' })
                  .formatted
              }
              target="_blank"
              rel="noreferrer"
            />
          ),
        };
        break;
      default:
        break;
    }

    return components;
  };

  Object.assign(agreement, {
    options: [
      {
        label: <Trans i18nKey={`shared.forms.agreement.label.${country}`} components={getTranslationComponent()} />,
        value: true,
      },
    ],
    colProps: { xs: 24, md: 18 },
  });

  const numberOfSelectedOptions = () => {
    switch (country) {
      case 'my':
        return [name, phone, agreement];
      case 'id':
        return [makeModel, phone];
      default:
        return [name, phone];
    }
  };

  return (
    <Form form={form} name="huntForm" layout="vertical" className="hunt-form" onValuesChange={handleFormValueChange}>
      <Row className="basic-info-row" gutter={16}>
        <Col className="gutter-row" xs={24} md={18} lg={18} xl={18}>
          <BuyHuntSearchFilter lang={lang} />
        </Col>
        <FormItems
          form={form}
          formItems={numberOfSelectedOptions()}
          defaultColProps={{ xs: 24, md: 9, lg: 9, xl: 9 }}
        />
        <Col className="gutter-row" xs={24} md={18}>
          <Button
            type="primary"
            className="hunt-form-btn"
            loading={isSubmitting}
            onClick={onSubmit}
            disabled={disableSubmitButton}
          >
            <img src={bellIcon} alt="bell Icon" className="bell-icon" width={15} height={20} />
            <span className="btn-text">{t('shared.buyHuntSection.findButton')}</span>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BuyHuntContainer;
