import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Form, Modal } from 'antd';
import debounce from 'lodash/debounce';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import upperCase from 'lodash/upperCase';

import { Trans, useTranslation } from 'react-i18next';
import { ExclamationCircleFilled } from '@ant-design/icons';
import useGTMEvent from '@source/hooks/useGTMEvent';
import { getStaticCDN, isDotNotation } from '@design-system/utils/utils';

import Logger from '@source/services/Logger';
import BuyHuntForm from '@source/pages/Home/components/BuyHunt/BuyHuntForm';
import useRegionConfig from '@source/hooks/useRegionConfig';
import useTicketService from '@source/pages/CarDetail/hooks/useTicketService';
import useBrowseServices from '@source/hooks/useBrowseServices';
import { getSource, getDeviceInfo } from '@source/utils/utils';
import { TRegionKey } from '@source/interface';
import { IGTMObjectProps } from '@source/interface/otp';
import useViewedCars from '@source/hooks/useViewedCars';
import useUserProfileServices from '@source/hooks/useUserProfileServices';
import useStoreSearchFilterHistory from '@source/hooks/useStoreSearchFilterHistory';
import { formatPhoneNumberWithCountryCode } from '@source/pages/CarDetail/utils';

const StyledWrapper = styled.div`
  width: 100%;
  color: ${(props) => props.theme.color.onSurface};

  .bell-icon-title {
    width: 24px;
    margin-bottom: 6px;
  }

  .ant-input-lg {
    height: 42px !important;
  }
  .not-looking-for-heading {
    margin-bottom: 20px;
    .not-looking-for-title {
      ${(props) => props.theme.typo.familyGoogle.semiBold};
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 4px;
      padding-right: 55px;
    }
    .not-looking-for-description {
      ${(props) => props.theme.typo.familyGoogle.regular};
      font-size: 14px;
      font-weight: 400;
      max-width: 65%;
    }
    .not-looking-for-notify-desc {
      color: ${(props) => props.theme.color.linkHighEmphasis};
    }
  }

  .bell-icon-button {
    color: ${(props) => props.theme.color.onPrimary};
    margin-top: -3px;
    margin-right: 6px;
    width: 14px;
  }
  .hunt-form-btn {
    width: 100%;
    height: 42px;
    text-transform: capitalize;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    font-size: 14px;
    color: ${(props) => props.theme.color.onPrimary} !important;
    background: ${(props) => props.theme.primaryColor};
    border-radius: 12px;
    border: none;
    &[disabled] {
      box-shadow: none !important;
      background: ${(props) => props.theme.color.disabled};
    }

    .bell-icon {
      color: ${(props) => props.theme.color.onPrimary} !important;
      width: 15px;
      margin-top: -3px !important;
    }

    .btn-text {
      margin-left: 10.5px;
      font-weight: 600;
    }
  }
  .ant-btn-primary[disabled] {
    color: ${(props) => props.theme.color.onBackgroundMediumEmphasis} !important;
  }
  .ant-divider {
    width: 80px;
    min-width: 80px;
    margin: 20px auto 0 auto;
    border-top: 2px solid ${(props) => props.theme.color.primaryBase};
  }

  #huntForm > div > {
    div:nth-child(2) {
      padding-right: 4px !important;
    }
    div:nth-child(3) {
      padding-left: 4px !important;
    }
  }

  @media only screen and (max-width: 1216px) {
    .not-looking-for-heading {
      margin-bottom: 20px;
      .bell-icon {
        width: 13px;
        margin-top: -1px;
        margin-right: 4px;
      }
      .not-looking-for-title {
        ${(props) => props.theme.typo.familyGoogle.regular};
        font-size: 16px;
        margin-bottom: 4px;
      }
      .not-looking-for-description {
        font-size: 14px;
      }
    }
    .hunt-form-btn {
      margin-top: 0;
      width: 100%;
    }
    .bell-icon-title {
      width: 15px;
    }
  }
  @media only screen and (max-width: 767px) {
    #huntForm > div > {
      div:nth-child(2) {
        padding-right: 8px !important;
      }
      div:nth-child(3) {
        padding-left: 8px !important;
      }
    }

    .not-looking-for-heading {
      margin-bottom: 24px;
    }
    .not-looking-for-description {
      width: 65%;
    }
  }
  @media only screen and (max-width: 575px) {
    .not-looking-for-title {
      width: 250px;
    }
  }
`;

const StyledCard = styled.div<{
  buyHuntImage?: string;
  country: TRegionKey;
}>`
  width: 100%;
  padding: 45px 32px;
  border: 1px solid ${(props) => props.theme.color.border};
  border-radius: 10px;
  background: ${(props) => props.theme.color.onPrimary} bottom 32px right 32px no-repeat
    url(${(props) => props.buyHuntImage});
  background-size: 280px 280px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);

  .basic-info-row {
    .ant-input,
    .ant-legacy-form-item-label label {
      ${(props) => props.theme.typo.familyGoogle.regular}
      font-size: 14px !important;
    }
  }

  .formContainer {
    ${(props) => props.theme.typo.familyGoogle.regular};
  }

  @media only screen and (max-width: 1280px) {
    padding: 32px;
    background: bottom right 30px no-repeat url(${(props) => props.buyHuntImage});
    background-size: 240px 300px;
  }

  @media only screen and (max-width: 1216px) {
    padding: 20px;
    background-size: auto 90%;
    background-position: center right 16px;

    .formContainer {
      width: 80%;
    }

    ${(props) =>
      props.country === 'my' &&
      css`
        background-size: auto 90%;
        background-position: bottom 50% right 16px;

        .formContainer {
          width: 74%;
        }
      `}
  }

  @media only screen and (max-width: 892px) {
    background-size: auto 87%;
    background-position-y: center;

    ${(props) =>
      props.country === 'my' &&
      css`
        background-size: auto 69%;
        background-position-y: 84%;
      `}
  }

  @media only screen and (max-width: 767px) {
    border: none;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background: ${(props) => props.theme.color.background} top 20px right 18px no-repeat
      url(${(props) => props.buyHuntImage});
    background-size: 100px 100px;
    .basic-info-row {
      .ant-legacy-form-item {
        margin-bottom: 20px !important;
      }
    }

    .formContainer {
      width: 100%;
    }
  }
`;

const StyledModal = styled(Modal)<{
  contentType?: string;
}>`
  ${(props) => props.theme.typo.familyGoogle.regular}
  text-align: center;
  .ant-modal-body {
    padding: 32px;
  }
  .ant-modal-content {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
  }
  .modal-container {
    .fail-icon {
      font-size: 46px;
      margin-bottom: 16px;
      color: ${(props) => props.theme.color.errorHighEmphasis};
    }

    .success-icon-button {
      width: 35px;
      margin-bottom: 6.5px;
    }
    .header {
      font-size: 20px;
      font-weight: 700;
      color: ${(props) =>
        props.contentType === 'success' ? props.theme.color.successHighEmphasis : props.theme.color.errorHighEmphasis};
      margin-bottom: 5px;
    }
    .content {
      margin-top: 8px;
      font-size: 14px;
    }
    .footer {
      margin-top: 25px;
      font-size: 12px;
      color: ${(props) => props.theme.color.gray};
    }
  }
`;

type BuyHuntContainerProps = {
  lang: string;
};

const BuyHuntContainer: FC<BuyHuntContainerProps> = ({ lang }: BuyHuntContainerProps) => {
  const {
    countryConfig: { country = 'sg' },
  } = useRegionConfig();
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const { getViewedCars } = useViewedCars();
  const { createBuyHuntTicket } = useTicketService();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formUpdating, setFormUpdating] = useState(0);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [isSubmittedSuccess, setIsSubmittedSuccess] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { pushGTMEvent } = useGTMEvent();
  const contentType: string = isSubmittedSuccess ? 'success' : 'fail';
  const buyHuntImage = getStaticCDN('/static/img/browse/carro-notify-me.svg');
  const bellIconTitle = getStaticCDN('/assets/shared/icons/bellIconTitle.svg');
  const bellIcon = disableSubmitButton
    ? getStaticCDN('/assets/shared/icons/bellIconDisbled.svg')
    : getStaticCDN('/assets/shared/icons/bellIconActive.svg');

  const { browseState } = useBrowseServices();
  const { currentQuery, notifyMeSearchFilterKeywords } = browseState;

  const { userProfileState, fetchProfile, isAuthenticated } = useUserProfileServices();
  const { profile } = userProfileState || {};
  const { phone: profilePhone, slug } = profile || {};
  const { getHistory, clearHistory } = useStoreSearchFilterHistory();

  const onClose = (): void => {
    setIsSubmitting(false);
    setVisible(false);
  };

  const handleTextModal = (): void => {
    setIsSubmittedSuccess(false);
  };

  const handleOnSubmit = async (): Promise<void> => {
    const viewedCars = getViewedCars(country);

    setIsSubmitting(true);
    const { resetFields } = form;
    let getSearchFilterKeys: { [key: string]: any } = {};

    Object.keys(notifyMeSearchFilterKeywords).reduce((searchFilterKeys, key) => {
      const KEY_REGEX = /\s\w+\s/;
      const BOOLEAN_REGEX = /^is|has\w*/;
      key.split(KEY_REGEX).forEach((result) => {
        const objectKey = result.split('.')[1]?.split('=')[0] || result;
        const isBooleanKey = BOOLEAN_REGEX.test(objectKey);
        if (isArray(notifyMeSearchFilterKeywords[key]) && notifyMeSearchFilterKeywords[key].length > 0) {
          const searchFilterOptions = notifyMeSearchFilterKeywords[key];
          // track only filters with content
          if (['range_input'].includes(searchFilterOptions[0].type)) {
            const rangeValue = searchFilterOptions[0];
            const { displayMultiplier } = rangeValue || {};

            let minValue = rangeValue?.min || 0;
            let maxValue = rangeValue?.max || 0;

            // temp fix: for the case: range input has display multiplier
            minValue = displayMultiplier ? minValue * displayMultiplier : minValue;
            maxValue = displayMultiplier ? maxValue * displayMultiplier : maxValue;

            getSearchFilterKeys[`${objectKey}_min`] = minValue;
            getSearchFilterKeys[`${objectKey}_max`] = maxValue;
          } else if (['number'].includes(searchFilterOptions[0].type)) {
            getSearchFilterKeys[`${objectKey}`] = searchFilterOptions[0]?.value;
          } else if (objectKey === 'listing') {
            searchFilterOptions.forEach((item: any) => {
              let newKey = item.value?.replace('listing', '')?.replace('flags', '')?.replace(/\./g, '');

              // temp fix: for the case: listing.has_360_interior_photos=1 OR listing.has_360_exterior_photos
              newKey = upperCase(newKey).includes('OR')
                ? item.value
                    ?.split('OR')
                    ?.find((k: string) => !k.includes('='))
                    ?.split('.')?.[1]
                : newKey;
              getSearchFilterKeys[`${newKey}`] = true;
            });
          } else {
            getSearchFilterKeys[objectKey] = searchFilterOptions.map((k: any) => {
              const newValue = k.formattedValue || k.value || k;

              return isDotNotation(newValue) ? t(newValue) : newValue;
            });
          }
        } else {
          getSearchFilterKeys[objectKey] = isBooleanKey
            ? true
            : notifyMeSearchFilterKeywords[key]?.value || notifyMeSearchFilterKeywords[key];

          if (getSearchFilterKeys[objectKey].length === 0) {
            delete getSearchFilterKeys[objectKey];
          }
        }
      });
      return getSearchFilterKeys;
    }, {});

    // include user query on search bar into search_data
    if (currentQuery.length > 0) {
      getSearchFilterKeys = {
        ...getSearchFilterKeys,
        currentQuery,
      };
    }

    try {
      const values: any = await form.validateFields();
      const { name, phone, makeModel } = values || {};
      const sourceData = getSource();
      const deviceInfo = getDeviceInfo('');
      // eslint-disable-next-line camelcase
      const search_filter_history = getHistory(country as TRegionKey);
      const payload = {
        context: `buy-hunt-carro-web-${country}`,
        additional_data: {
          name,
          phone,
          ...(getSearchFilterKeys?.make_model || currentQuery.length > 0
            ? { car_make_model: getSearchFilterKeys?.make_model?.join(', ') }
            : { car_make_model: makeModel || currentQuery }),
          filterUrl: window?.location?.href,
          viewed_cars: viewedCars,
          // eslint-disable-next-line camelcase
          search_filter_history: isAuthenticated() ? undefined : search_filter_history,
        },
        search_data: { ...getSearchFilterKeys },
        tracking_data: {
          ...deviceInfo,
          ...sourceData,
        },
      };

      clearHistory(country);

      setIsSubmitting(true);
      const resp = await createBuyHuntTicket({ country: country || 'sg', payload, isAuthenticated: isAuthenticated() });
      if (!resp.error) {
        // after creating buy hunt ticket, call fetchProfile again to get latest profile data
        try {
          if (phone === profilePhone || (phone && profilePhone && profilePhone.includes(phone))) {
            // if user profile's phone same with the phone when submit buy hunt
            fetchProfile({ country: country || 'sg', slug: slug || '' });
          }
        } catch (error) {
          Logger.error('Error on fetching profile after buy hunt', error);
        }

        setIsSubmitting(false);
        setIsSubmittedSuccess(true);
        setVisible(true);
        resetFields();
        setFormUpdating(0);
        debounce(handleTextModal, 1000);

        const GTMObj: IGTMObjectProps = {
          event: 'submitted_buyhunt',
          enhanced_conversion_data: {
            phone_number: formatPhoneNumberWithCountryCode(phone, country),
            name,
          },
          page_name: 'homepage',
          ticket_id: resp?.payload?.id,
          country: country || 'sg',
        };

        pushGTMEvent(GTMObj);
      } else {
        setVisible(true);
      }
    } catch (error) {
      setIsSubmitting(false);
      Logger.log(error);
    }
  };

  const onFormUpdating = () => {
    if (form.isFieldsTouched(true)) {
      form.validateFields().then(() => {
        setDisableSubmitButton(false);
      });
    }
    setDisableSubmitButton(true);
  };

  const handleFormValueChange = () => {
    if (form) {
      setFormUpdating(formUpdating + 1);
    }
  };

  useEffect(() => {
    if (onFormUpdating) {
      onFormUpdating();
    }
  }, [formUpdating]);

  return (
    <StyledWrapper id="notify-me-section">
      <StyledCard buyHuntImage={buyHuntImage} country={country}>
        <div className="not-looking-for-heading">
          <h2 className="not-looking-for-title">
            {!isEmpty(notifyMeSearchFilterKeywords) ? (
              <>
                <img src={bellIconTitle} alt="bell Icon" className="bell-icon-title" />{' '}
                {country === 'hk'
                  ? t('shared.buyHuntSection.filteredTitleHK')
                  : t('shared.buyHuntSection.filteredTitle')}
              </>
            ) : (
              t('shared.buyHuntSection.title')
            )}
          </h2>
          <div className="not-looking-for-description">
            {!isEmpty(notifyMeSearchFilterKeywords) ? (
              <Trans
                i18nKey={
                  // eslint-disable-next-line no-nested-ternary
                  country === 'my'
                    ? 'shared.buyHuntSection.descriptionFilteredMY'
                    : country === 'hk'
                    ? 'shared.buyHuntSection.descriptionFilteredHK'
                    : 'shared.buyHuntSection.descriptionFiltered'
                }
                components={{ br: <br /> }}
              />
            ) : (
              <Trans
                i18nKey={
                  // eslint-disable-next-line no-nested-ternary
                  country === 'my'
                    ? 'shared.buyHuntSection.descriptionMY'
                    : country === 'hk'
                    ? 'shared.buyHuntSection.descriptionHK'
                    : 'shared.buyHuntSection.description'
                }
                components={{ br: <br /> }}
              />
            )}
          </div>
        </div>
        <div className="formContainer">
          <BuyHuntForm
            lang={lang}
            form={form}
            onSubmit={handleOnSubmit}
            isSubmitting={isSubmitting}
            disableSubmitButton={disableSubmitButton}
            handleFormValueChange={handleFormValueChange}
            bellIcon={bellIcon}
          />
        </div>
      </StyledCard>
      <StyledModal
        width={480}
        closable={false}
        visible={visible}
        footer={null}
        onCancel={onClose}
        destroyOnClose
        contentType={contentType}
      >
        <div className="modal-container">
          {contentType === 'success' ? (
            <img
              className="success-icon-button"
              src={getStaticCDN('/static/img/browse/icons/checkMark_icon.svg')}
              alt="icon"
            />
          ) : (
            <ExclamationCircleFilled className="fail-icon" />
          )}
          <div className="header">{t(`shared.buyHuntSection.${contentType}.title`)}</div>
          <div className="content">
            <Trans defaults={t(`shared.buyHuntSection.${contentType}.message`)} components={{ br: <br /> }} />
          </div>
          <div className="footer">{t(`shared.buyHuntSection.${contentType}.btnLabel`)}</div>
        </div>
      </StyledModal>
    </StyledWrapper>
  );
};

export default BuyHuntContainer;
