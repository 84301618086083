import { useEffect } from 'react';

import useBrowseServices from '@source/hooks/useBrowseServices';
import { SettingProps } from '@design-system/components/FiltersV1/FilterProps';

export interface CurrentFilterSettingsOnChangePayload {
  currentFilterSettings: SettingProps[];
  currentQuery?: string;
  filterChangeCount?: number;
}

export default function useCurrentFilterSettingsOnChange(
  onChange: (payload: CurrentFilterSettingsOnChangePayload) => void,
) {
  const { browseState } = useBrowseServices();
  const { currentQuery, currentFilterSettings, filterChangeCount } = browseState;

  useEffect(() => {
    onChange({ currentFilterSettings, currentQuery, filterChangeCount });
  }, [currentFilterSettings, currentQuery, filterChangeCount]);
}
